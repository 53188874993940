.bg-gradient-grid-green {
  background-color: #000000;
  background-image: 
    linear-gradient(rgba(0, 255, 60, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 255, 60, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  position: relative;
  overflow: hidden;
}

.bg-gradient-grid-green::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center, 
    rgba(0, 255, 60, 0.1) 0%, 
    transparent 70%
  );
  pointer-events: none;
}

.typewriter {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: 
    typing 1.5s steps(40, end) forwards;
  animation-delay: 1s;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.letter-animation {
  display: inline-block;
  opacity: 0;
  transform: translateY(-20px);
  animation: letterDrop 0.5s ease forwards;
}

@keyframes letterDrop {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.letter-animation:nth-child(1) { animation-delay: 0.1s; }
.letter-animation:nth-child(2) { animation-delay: 0.2s; }
.letter-animation:nth-child(3) { animation-delay: 0.3s; }
.letter-animation:nth-child(4) { animation-delay: 0.4s; }
.letter-animation:nth-child(5) { animation-delay: 0.5s; }
.letter-animation:nth-child(6) { animation-delay: 0.6s; }
.letter-animation:nth-child(7) { animation-delay: 0.7s; }
.letter-animation:nth-child(9) { animation-delay: 0.9s; }

.letter-animation:hover {
  color: #00FF3C;
  text-shadow: 0 0 10px rgba(0, 255, 60, 0.5);
  transition: all 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-fade-in span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.animate-fade-in span:nth-child(1) { animation-delay: 0.1s; }
.animate-fade-in span:nth-child(2) { animation-delay: 0.2s; }
.animate-fade-in span:nth-child(3) { animation-delay: 0.3s; }
.animate-fade-in span:nth-child(4) { animation-delay: 0.4s; }
.animate-fade-in span:nth-child(5) { animation-delay: 0.5s; }
.animate-fade-in span:nth-child(6) { animation-delay: 0.6s; }
.animate-fade-in span:nth-child(7) { animation-delay: 0.7s; }
.animate-fade-in span:nth-child(9) { animation-delay: 0.9s; }