@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

/* Base Styles & Variables */
:root {
  --primary-color: #08da22;
  --secondary-color: #052e0b;
  --accent-color: #05ff29;
  --dark-bg: #0a0a0a;
  --card-bg: rgba(255, 255, 255, 0.08);
  --card-border: rgba(255, 255, 255, 0.1);
  --glass-effect: blur(12px);
  --transition-speed: 0.3s;
  --shadow-glow: 0px 0px 20px rgba(8, 218, 34, 0.3);
  --font-heading: "Montserrat", sans-serif;
  --font-body: "Mont", "Montserrat", sans-serif;
  --navbar-height: 70px;
}

/* Landing Page Main Container */
#landing-main {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  background:transparent;
  padding: 2rem 0;
}

/* Move PrizePool styling outside of landing-main */
.prize-pool-container {
  position: relative;
  width: 100%;
  background-color: var(--dark-bg); /* Add a background color for PrizePool */
  z-index: 2;
}

/* Added navbar spacer to prevent content from being hidden under navbar */
.navbar-spacer {
  height: var(--navbar-height);
  width: 100%;
}

/* Background Effect */
#effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: transparent;
  pointer-events: none;
}

#effect canvas {
  border-radius: 20px;
}

/* Particles animation */
.particle {
  position: absolute;
  width: 2px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  pointer-events: none;
  animation: float 20s infinite linear;
}

@keyframes float {
  0% {
    transform: translateY(100vh) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(-100vh) scale(1);
    opacity: 0;
  }
}

/* Content Layout */
.lcontent {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 85%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 3rem;
  gap: 3rem;
  background: transparent;
}

.lleft, .lright {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Left Side - Event Info */
.lleft {
  justify-content: center;
}

.event-title h1 {
  font-family: var(--font-heading);
  font-size: 5rem;
  font-weight: 800;
  letter-spacing: 4px;
  margin: 0;
  line-height: 1;
  background: linear-gradient(135deg, #fff 40%, var(--accent-color) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.event-title .accent {
  font-size: 5.5rem;
  display: inline-block;
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
  margin-left: 0.5rem;
  position: relative;
}

.coming-soon-badge {
  display: inline-block;
  background: rgba(8, 218, 34, 0.1);
  color: var(--primary-color);
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
  border: 2px solid var(--primary-color);
}

.tagline {
  margin: 1.5rem 0 2.5rem;
}

.tagline p {
  font-family: var(--font-accent);
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0.5rem 0;
  letter-spacing: 1.5px;
  font-style: italic;
  opacity: 0.9;
}

.hashtag {
  color: var(--accent-color);
  font-weight: 600;
  font-family: var(--font-heading);
  letter-spacing: 2px;
  font-style: normal;
}

.event-details {
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
}

.date-badge {
  display: inline-block;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  padding: 0.7rem 1.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: fit-content;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

.date-badge:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.date-icon {
  margin-right: 0.75rem;
}

.event-type {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--accent-color);
  margin-left: 0.75rem;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  max-width: 350px;
}

/* Right Side - Logo & Social */
.lright {
  align-items: center;
}

.logo-container {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 2.5rem;
  margin-bottom: 3rem;
  box-shadow: 0 0 30px rgba(8, 218, 34, 0.2);
  border: 1px solid rgba(8, 218, 34, 0.3);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-container:hover {
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 0 50px rgba(8, 218, 34, 0.5);
}

.logo-container img {
  height: 14rem;
  max-width: 100%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-container h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  position: relative;
}

.social-container h3:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: var(--primary-color);
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-btn {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all var(--transition-speed);
  backdrop-filter: var(--glass-effect);
}

.social-btn:hover {
  transform: translateY(-5px);
}

.instagram:hover {
  background: linear-gradient(
    45deg,
    #f09433,
    #e6683c,
    #dc2743,
    #cc2366,
    #bc1888
  );
  color: white;
}

.linkedin:hover {
  background: #0077b5;
  color: white;
}

.discord:hover {
  background: #7289da;
  color: white;
}

.telegram:hover {
  background: #0088cc;
  color: white;
}

/* Presented By Section */
.presented-by {
  position: relative;
  z-index: 1;
  margin-top: 5rem;
  padding: 1.5rem;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transform: none;
}

.presented-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--card-border);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  backdrop-filter: var(--glass-effect);
  box-shadow: var(--shadow-glow);
  transition: all 0.3s ease;
  width: 90%;
  opacity: 1;
  visibility: visible;
  transform: none;
}

.presented-content:hover {
  box-shadow: 0 10px 30px rgba(8, 218, 34, 0.3);
  transform: translateY(-5px);
  background: rgba(0, 0, 0, 0.9);
}

.presented-content span {
  font-size: 1.2rem;
  opacity: 0.9;
  font-family: var(--font-accent);
  font-style: italic;
  color: var(--accent-color);
  display: block;
  margin-bottom: 0.5rem;
}

.presented-content h2 {
  font-size: 2rem;
  margin: 0.5rem 0;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: white;
  text-transform: uppercase;
}

.presented-content h3 {
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.9;
  margin: 0.5rem 0 0;
  letter-spacing: 1px;
  color: var(--primary-color);
}

/* Tracks Section */
.tracks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.track-item {
  background: rgba(8, 218, 34, 0.1);
  color: var(--primary-color);
  padding: 0.5rem 1.2rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--primary-color);
  transition: all 0.3s ease;
}

.track-item:hover {
  background: rgba(8, 218, 34, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(8, 218, 34, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
  #landing-main {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    overflow: hidden;
    background:transparent;
    padding: 2rem 0;
  }
  .lcontent {
    width: 90%;
    background: transparent;
  }
  
  .event-title h1 {
    font-size: 4rem;
  }

  .event-title .accent {
    font-size: 4.5rem;
  }

  .logo-container img {
    height: 12rem;
  }
}

@media (max-width: 768px) {
  .lcontent {
    background: transparent;
    flex-direction: column;
    padding-top: 2rem;
    gap: 2rem;
    width: 95%;
  }

  .lleft, .lright {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .event-title h1 {
    font-size: 3.5rem;
  }

  .event-title .accent {
    font-size: 4rem;
  }

  .tagline p {
    font-size: 1.2rem;
  }

  .date-badge, .event-type {
    margin: 0 auto;
  }

  .action-buttons {
    max-width: 100%;
    width: 100%;
  }

  .presented-by {
    margin-top: 3rem;
  }

  .presented-content {
    padding: 1.2rem 1.5rem;
    width: 95%;
  }

  .presented-content h2 {
    font-size: 1.6rem;
  }

  .presented-content h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  #landing-main {
   background: transparent;
    padding: 1rem 0;
  }
  .lcontent {
    background: transparent;
    width: 95%;
    padding-top: 1rem;
  }

  .navbar-spacer {
    height: 60px;
  }

  .event-title h1 {
    font-size: 2.8rem;
  }

  .event-title .accent {
    font-size: 3.2rem;
  }

  .logo-container {
    padding: 1.5rem;
  }

  .logo-container img {
    height: 8rem;
  }

  .social-btn {
    width: 2.5rem;
    height: 2.5rem;
  }

  .presented-by {
    margin-top: 2rem;
  }

  .presented-content {
    padding: 1rem;
    margin: 0 1rem;
    width: 90%;
  }

  .presented-content span {
    font-size: 1rem;
  }

  .presented-content h2 {
    font-size: 1.4rem;
  }

  .presented-content h3 {
    font-size: 1rem;
  }
}

/* Add this if PrizePool needs to be above the background */
#landing-main > div {
  position: relative;
  background: transparent;
  z-index: 1;
}

