.faqs-section {
  padding: 4rem 0;
  background: transparent;
  position: relative;
  z-index: 2;
  overflow-y: auto;
}

.faqs-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding:1rem; /* added padding bro */
}

.faqs-content h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--primary-color);
  font-weight: 700;
}

.faqs-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faqs-list + .faqs-list {
  margin-top: 1rem;
}

.faq-item {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-question {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.faq-item.open .faq-question {
  background-color: green;
  color: black;
}


.faq-question h3 {
  margin: 0;
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
}

.arrow {
  color: var(--accent-color);
  transition: transform 0.3s ease;
}

.arrow.up {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
}

.faq-item.open .faq-answer {
  max-height: 300px;
}

.faq-answer p {
  margin: 0;
  padding: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

 .more-btn , .less-btn{ /*added the more button for the faq's bro */
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: green;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.more-btn{
  background: green;
}

.more-btn:hover{
  background: rgb(1, 176, 1);
}

.more-btn:hover,.less-btn:hover {
  background: var(--accent-color);
}

@media (max-width: 768px) {
  .more-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.3rem;
  }
  .more-btn, .less-btn:hover{
    background: rgb(1, 176, 1);
  }
}

@media (max-width: 480px) {
  .more-button {
    font-size: 0.85rem;
    padding: 0.6rem 1.2rem;

  }
  .more-btn, .less-btn:hover{
    background: rgb(1, 176, 1);
  }
}


@media (max-width: 768px) {
  .faqs-content h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .faq-question h3 {
    font-size: 1.1rem;
    text-align: left;
  }
  .faq-answer{
    text-align: left;
  }
}

@media (max-width: 480px) {
  .faqs-content h2 {
    font-size: 1.8rem;
  }

  .faq-question {
    padding: 1.2rem;
    text-align: left;
  }

  .faq-question h3 {
    font-size: 1rem;
    text-align: left;
  }

  .faq-answer p {
    padding: 1.2rem;
    font-size: 0.9rem;
    text-align: left;
  }
} 