@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	scroll-behavior: smooth;
}

.conta::-webkit-scrollbar {
	display: none;
}

.conta {
	-ms-overflow-style: none;
	scrollbar-width: none;
	background: transparent;
}

.conta {
	
	height: 100%;
	overflow-y: auto;
	overscroll-behavior-y: contain;
	scroll-snap-type: y mandatory;
	scroll-snap-points-y: repeat(400px);
	position: absolute;
	width: 100%;
}

.scroll {
	scroll-snap-align: center;
	height: 100%;
	scroll-snap-stop: always;
}

#root {
	margin: 1.5rem;
	width: 97%;
	background-color: transparent;
	border: 0.1px solid rgb(64, 64, 64);
	height: 95vh;
	border-radius: 20px;
	position: fixed;
	overflow: hidden;
}

@font-face {
	font-family: OmegaFlight;
	src: url("./fonts/OmegaFlight-BWjvx.otf");
}

@font-face {
	font-family: Conversion;
	src: url("./fonts/conversion.ttf");
}

@font-face {
	font-family: Mont;
	src: url('./fonts/Mont-HeavyDEMO.otf');
	font-weight: bold;
}

@font-face {
	font-family: Mont;
	src: url('./fonts/Mont-ExtraLightDEMO.otf');
	font-weight: normal;
}

body {
	font-family: 'OmegaFlight';
	background-color: rgba(0, 0, 0, 0.938);
	margin: 0;
	padding: 0;
	overflow: hidden;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.938)
}

body::-webkit-scrollbar {
	width: 6px;
	background-color: rgba(0, 0, 0, 0.938)
}

body::-webkit-scrollbar-thumb {
	background-color: #F5F5F5;
}

html {
	scroll-behavior: smooth;
}

.glass {
	background-color: rgba(255, 255, 255, 0.06);
	border: 1px solid rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px);
	box-shadow: 0px 0px 20px 5px #0000008c;
	transition: all 0.3s;
}

.slick-slide > div {
	margin: 0 10px;
  }
  
  .slick-dots li.slick-active button:before {
	color:orange !important;
  }
  

@media only screen and (max-width: 640px) {
	#root {
		margin: .45rem;
		width: 96.5%;
		background-color: rgb(16, 16, 16);
		border: 0.1px solid rgb(64, 64, 64);
		height: 97vh;
		border-radius: 20px;
		position: fixed;
		background: transparent;
	}
}